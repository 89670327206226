import { FlowEditorSDK } from '@wix/yoshi-flow-editor';
import { WidgetId } from '@wix/members-area-app-definitions';

import type {
  EditorContextServices,
  RouteConfiguration,
  RoutesConfigurationServiceEditor,
  UpdateRouteConfigurationRequest,
} from '../../types';
import { Experiment } from '../../constants';
import { shouldUseNewRoutesService } from '../services/utils';
import { maybeNavigateToMemberPage } from '../services/navigation';
import { log } from '../services/monitor';
import { getMembersAreaUmbrellaPublicAPI } from '../services/members-editor-script';
import { globalAppState } from '../services';
import { refreshApp } from '../editor-sdk-wrappers';
import { updateMemberPageControllerWithRoutes } from './member-page-controller';
import {
  getRoutesFromGlobalController,
  updateGlobalControllerWithRoutes,
} from './global-controller';

type ReorderRoutesContextProps = {
  editorSDK: FlowEditorSDK;
  sourceIndex: number;
  targetIndex: number;
};

export const getRoutes = async (
  editorSDK: FlowEditorSDK,
  routeService: RoutesConfigurationServiceEditor,
) => {
  if (await shouldUseNewRoutesService(editorSDK)) {
    return routeService.fetchRouteConfigurations();
  }

  const flowAPI = globalAppState.getFlowAPI();
  if (flowAPI?.experiments.enabled(Experiment.UseAppDataForRoutes)) {
    const membersAreaPublicAPI = await getMembersAreaUmbrellaPublicAPI(
      editorSDK,
    );
    return membersAreaPublicAPI.getRoutes();
  }

  return getRoutesFromGlobalController(editorSDK);
};

export const updateRoutesConfig = async (
  editorSDK: FlowEditorSDK,
  updatedRoutes: RouteConfiguration[],
) => {
  const flowAPI = globalAppState.getFlowAPI();

  // TODO: Should we stop updating global controller?
  await updateGlobalControllerWithRoutes(editorSDK, updatedRoutes);

  if (flowAPI?.experiments.enabled(Experiment.UseAppDataForRoutes)) {
    const membersAreaPublicAPI = await getMembersAreaUmbrellaPublicAPI(
      editorSDK,
    );
    await membersAreaPublicAPI.setRoutes(updatedRoutes);
  }

  const avoidNavigation =
    globalAppState.getIsClassicEditor() ||
    flowAPI?.experiments.enabled(Experiment.RemoveUpdatingBoBController);

  if (avoidNavigation) {
    return;
  }

  if (!flowAPI?.experiments.enabled(Experiment.DontWaitForBoBController)) {
    await updateMemberPageControllerWithRoutes(editorSDK, updatedRoutes);
    return;
  }

  // Updating BoB controller should be unecessary after global controller is available everywhere
  // Until then this is for fallback for pages without a header
  await maybeNavigateToMemberPage(editorSDK);

  updateMemberPageControllerWithRoutes(editorSDK, updatedRoutes).catch(
    (e: any) => {
      log('Warning: Failed to update BoB controller', {
        extra: {
          error: typeof e === 'string' ? e : (e as Error).message,
        },
      });
    },
  );
};

const updateRouteInConfig = async (
  editorSDK: FlowEditorSDK,
  routeService: RoutesConfigurationServiceEditor,
  updatedRoute: RouteConfiguration,
) => {
  const routes = await getRoutes(editorSDK, routeService);

  if (!routes.length) {
    return updatedRoute;
  }

  const updatedRoutes = routes.map((route) => {
    return route.widgetId === updatedRoute.widgetId
      ? { ...route, ...updatedRoute }
      : route;
  });

  await updateRoutesConfig(editorSDK, updatedRoutes);
  await refreshApp(editorSDK);

  return updatedRoute;
};

export const updateRoute = async (
  editorSDK: FlowEditorSDK,
  routeService: RoutesConfigurationServiceEditor,
  payload: UpdateRouteConfigurationRequest,
) => {
  if (await shouldUseNewRoutesService(editorSDK)) {
    return routeService.updateRouteConfiguration(payload);
  }

  return updateRouteInConfig(
    editorSDK,
    routeService,
    payload.routeConfiguration,
  );
};

const removeRoute = async (
  editorSDK: FlowEditorSDK,
  routeService: RoutesConfigurationServiceEditor,
  widgetId: string,
) => {
  const routes = await getRoutes(editorSDK, routeService);
  if (!routes.length) {
    return;
  }

  const filteredRoutes = routes.filter((route) => route.widgetId !== widgetId);

  return updateRoutesConfig(editorSDK, filteredRoutes);
};

const removeRoutesFromConfig = async (
  editorSDK: FlowEditorSDK,
  routeService: RoutesConfigurationServiceEditor,
  widgetsIds: WidgetId[],
) => {
  // TODO: use removeRoutesFromGlobalController - no need to update in loop
  for (const widgetId of widgetsIds) {
    await removeRoute(editorSDK, routeService, widgetId);
  }
};

export const removeRoutes = async (
  editorSDK: FlowEditorSDK,
  routeService: RoutesConfigurationServiceEditor,
  widgetsIds: WidgetId[],
) => {
  if (await shouldUseNewRoutesService(editorSDK)) {
    return routeService.bulkDeleteRouteConfigurations(widgetsIds);
  }

  return removeRoutesFromConfig(editorSDK, routeService, widgetsIds);
};

/** @deprecated */
export const reorderRoutes = async (
  { editorSDK, sourceIndex, targetIndex }: ReorderRoutesContextProps,
  { routeService }: Pick<EditorContextServices, 'routeService'>,
) => {
  if (await shouldUseNewRoutesService(editorSDK)) {
    return routeService.reorderRouteConfigurations(sourceIndex, targetIndex);
  }
};
