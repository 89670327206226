import { FlowEditorSDK } from '@wix/yoshi-flow-editor';
import { MEMBERS_AREA } from '@wix/app-definition-ids';
import {
  COMPONENTS_SECTION,
  COMPONENTS_SECTION_RESPONSIVE,
  Experiment,
  MEMBER_TPA_PAGE_ID,
  PROFILE_PAGE_BOB_APP_DEF_ID,
} from '../../constants';
import { getDataByAppDefId } from '../editor-sdk-wrappers/tpa';
import {
  addPage,
  renamePage,
  updatePageData,
} from '../editor-sdk-wrappers/pages';
import { getChildren, getType } from '../editor-sdk-wrappers';
import { ComponentRef } from '@wix/platform-editor-sdk';
import { runAndWaitForApproval } from '../editor-sdk-wrappers/document';
import { PlatformContext } from '../../types';
import { breakpoints } from '../definitions/breakpoints';
import { updateMenusMembersPath } from './menus-wrapper';
import { globalAppState } from './global-app-state';
import { APP_TOKEN } from '../../constants/widget';

const getProfilePageBoBTpaApplicationId = async (editorSDK: FlowEditorSDK) => {
  const data = await getDataByAppDefId(editorSDK, PROFILE_PAGE_BOB_APP_DEF_ID);
  return data.applicationId;
};

export const createProfilePage = async ({
  editorSDK,
  flowAPI,
}: PlatformContext) => {
  const avoidNavigation =
    globalAppState.getIsClassicEditor() ||
    flowAPI.experiments.enabled(Experiment.RemoveUpdatingBoBController);
  const tpaApplicationId = await getProfilePageBoBTpaApplicationId(editorSDK);
  const { isEditorX } = flowAPI.environment;

  return runAndWaitForApproval(editorSDK, () =>
    addPage(editorSDK, {
      title: flowAPI.translations.t('app.page.title'),
      definition: {
        id: '',
        type: 'Page',
        data: {
          managingAppDefId: MEMBERS_AREA,
          tpaPageId: MEMBER_TPA_PAGE_ID,
          pageUriSEO: 'members-area',
          tpaApplicationId,
        },
        componentType: 'mobile.core.components.Page',
        breakpoints: isEditorX ? breakpoints : undefined,
      },
      shouldAddMenuItem: false,
      shouldNavigateToPage: !avoidNavigation,
    }),
  );
};

export enum PageTitleType {
  Social = 'social',
  NonSocial = 'non-social',
}

const getProfilePageData = async (editorSDK: FlowEditorSDK) => {
  const pagesData = await editorSDK.document.pages.getApplicationPages('', {
    includeUnmanaged: true,
  });
  const pageData = pagesData.find(
    ({ tpaPageId }) => tpaPageId === MEMBER_TPA_PAGE_ID,
  );

  if (pageData) {
    return pageData;
  }

  throw new Error('[MAV2] Members Area page not found');
};

export const getMembersAreaPageRef = async (editorSDK: FlowEditorSDK) => {
  return editorSDK.document.tpa.getPageRefByTPAPageId('', {
    tpaPageId: MEMBER_TPA_PAGE_ID,
  });
};

export async function getMembersAreaPageId(editorSDK: FlowEditorSDK) {
  const membersAreaPageRef = await getMembersAreaPageRef(editorSDK);
  const membersAreaPage = await editorSDK.pages.data.get(APP_TOKEN, {
    pageRef: membersAreaPageRef,
  });

  if (!membersAreaPage?.id) {
    throw new Error('Members Area main page id is not found');
  }

  return membersAreaPage.id;
}

export const isMemberPageInstalled = async (editorSDK: FlowEditorSDK) =>
  !!(await getMembersAreaPageRef(editorSDK));

export const renameMembersAreaPage = async (
  editorSDK: FlowEditorSDK,
  newPageTitle: string,
) => {
  const pageRef = await getMembersAreaPageRef(editorSDK);
  if (!pageRef) {
    throw new Error('[MAV2] Members Area page ref not found');
  }
  const { title } = await getProfilePageData(editorSDK);

  if (newPageTitle !== title) {
    return renamePage(editorSDK, { pageRef, title: newPageTitle });
  }
};

export const updatePageUriSEO = async (
  editorSDK: FlowEditorSDK,
  newPageUriSEO: string,
) => {
  const { id, pageUriSEO } = await getProfilePageData(editorSDK);
  if (newPageUriSEO === pageUriSEO) {
    return;
  }

  await Promise.all([
    updateMenusMembersPath(editorSDK, pageUriSEO, newPageUriSEO),
    updatePageData(editorSDK, {
      pageRef: { id: id!, type: 'DESKTOP' },
      data: { pageUriSEO: newPageUriSEO },
    }),
  ]);
};

export const findSectionInPage = async (
  { editorSDK, flowAPI }: PlatformContext,
  pageRef: ComponentRef,
) => {
  const pageChildren = await getChildren(editorSDK, pageRef);
  const types = await Promise.all(
    pageChildren.map((childRef) => getType(editorSDK, childRef)),
  );
  const sectionType = flowAPI.environment.isEditorX
    ? COMPONENTS_SECTION_RESPONSIVE
    : COMPONENTS_SECTION;
  const sectionIndex = types.findIndex((type) => type === sectionType);

  return pageChildren[sectionIndex];
};
