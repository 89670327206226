import { ComponentRef, FlowEditorSDK } from '@wix/yoshi-flow-editor';

import { setControllerConfigProp } from './utils';
import { RouteConfiguration } from '../../types';
import { CONTROLLER_PROP_CONFIG_KEY_ROUTES } from '../../constants';
import { getProfilePageBobWidgetRef, waitForResult } from '../services';
import { maybeNavigateToMemberPage } from '../services/navigation';

const getController = async (
  editorSDK: FlowEditorSDK,
  componentRef: ComponentRef,
) => {
  const [controllerRef] = await editorSDK.components.getChildren('', {
    componentRef,
  });

  return controllerRef;
};

export const waitForMemberPageController = async (
  editorSDK: FlowEditorSDK,
  widgetRef: ComponentRef,
) => {
  await maybeNavigateToMemberPage(editorSDK);

  return waitForResult(
    () => getController(editorSDK, widgetRef),
    'Retrieve of controller with waitFor',
    10,
    400,
  );
};

/**
 * @deprecated
 * Routes are not stored in Member Page controller anymore.
 * Use Public API of Umbrella application to manage routes.
 */
export const updateMemberPageControllerWithRoutes = async (
  editorSDK: FlowEditorSDK,
  routes: RouteConfiguration[],
) => {
  const widgetRef = await getProfilePageBobWidgetRef(editorSDK);
  const controllerRef = await waitForMemberPageController(editorSDK, widgetRef);

  if (controllerRef) {
    await setControllerConfigProp({
      editorSDK,
      controllerRef,
      key: CONTROLLER_PROP_CONFIG_KEY_ROUTES,
      value: routes,
    });
  }
};
